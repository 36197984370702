import React, { useState, useEffect, useRef } from "react";
import { Loader, Dimmer, Menu, Modal, Radio, Grid } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

import {
  deleteWorkOrder,
  getAllWorkOrderByID,
  humanize,
} from "../../../config/functions";

import Details from "./details";
import Comment from "./comment";
import { selectTranslations } from "../../../config/i18n/slice";

import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { useSelector } from "react-redux";
import ProcedureItems from "./ProcedureItems";
import { toast } from "react-toastify";
import {
  Close,
  CloseIcon,
  CostIcon,
  DetailsViewIcon,
  LaborIcon,
  LogIcon,
  PartDetailIcon,
  ProcedureDetailsIcon,
  Success,
} from "../../../../Assets/Icons/svg";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import { useDeleteResultModal } from "../../../../hooks/Messages/useDeleteResultModal";
import { useDeletePermissionModal } from "../../../../hooks/Messages/useDeletePermissionModal";
import moment from "moment";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import { Resizable } from "re-resizable";
import Labor from "./laborTab/labor";
import Cost from "./costTab/cost";
import Part from "./partTab/part";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
function Preview({
  setTotalPartCount,
  totalPartCount,
  toggled,
  untoggle,
  setModalWidth,
  modalWidth,
  targetedData,
  workOrderID,
  negative,
  edit,
  refresh,
  setRefresh,
  seriesEdit,
  setSeriesEdit,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
}) {
  const t = useSelector(selectTranslations);
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [fetching, setFetchLoading] = useState(true);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [seriesModal, setSeriesModal] = useState(false);
  const [deleteSeriesModal, setDeleteSeriesModal] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  /* headers for excel and pdf file */
  const headers = [
    { label: t.workOrders.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    { label: t.assets.form.description, key: "description" },
    { label: t.workOrders.table.status, key: "status" },
    { label: t.workOrders.table.priority, key: "priority" },
    { label: t.workOrders.table.assignees, key: "assignees" },
    { label: t.assets.form.category, key: "category" },
    { label: t.workOrders.form.code, key: "code" },
    { label: t.workOrders.table.customer, key: "customer" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.workOrders.table.procedure, key: "procedure" },
    { label: t.workOrders.details_view.estimation_time, key: "estimationTime" },
    { label: t.workOrders.table.dueDate, key: "dueDate" },
    { label: t.workOrders.table.createdByUser, key: "created By" },
    { label: t.workOrders.table.createdAt, key: "created At" },
  ];

  const codeOptions = [
    {
      value: "sop",
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: "project",
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: "preventive",
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: "inspection",
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: "damage",
      label: t.workOrders.work_order_category.Damage,
    },
  ];

  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "green",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "darkblue",
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: "reOpen",
      color: "light-purple",
      label: t.workOrders.work_order_status.reOpen,
    },
  ];

  /* The above code is a React useEffect hook that is used to fetch data based on the `workOrderID`
value. */
  useEffect(() => {
    setFetchLoading(true);

    if (workOrderID) {
      const fetchData = async () => {
        const fetchData = await getAllWorkOrderByID(workOrderID);
        if (fetchData) {
          setData(fetchData);
          setFetchLoading(false);
        }
      };

      fetchData();
    } else {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData, workOrderID]);

  const handleEdit = () => {
    if (targetedData.attributes.orderType == "scheduled") {
      if (["inReview", "onHold"].includes(targetedData.attributes.status)) {
        setSeriesEdit(false);
      }
      setSeriesModal(true);
    } else {
      edit();
    }
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    if (targetedData.attributes.orderType == "scheduled") {
      setDeleteSeriesModal(true);
    } else {
      setDeletePermissionModal(true);
    }
    setPopupIsOpen(false);
  };

  const panes = [
    // Details tab (always the first tab)
    {
      menuItem: `${t.workOrders.details_view.details}`,
      icon: (
        <DetailsViewIcon color={activeIndex === 0 ? "#0C66E4" : "#788CA5"} />
      ),
      pane: {
        key: "details",
        content: (
          <div className="pane-body">
            <Details
              data={data}
              setTotalPartCount={setTotalPartCount}
              totalPartCount={totalPartCount}
              setData={setData}
              setActiveIndex={setActiveIndex}
              negative={negative}
              untoggle={untoggle}
              setModalWidth={setModalWidth}
              setRefresh={setRefresh}
              refresh={refresh}
              setSearchTitle={setSearchTitle}
              setSelectedDates={setSelectedDates}
              setFilterOptions={setFilterOptions}
            />
          </div>
        ),
      },
    },

    // Procedure tab (conditionally included)
    ...(data?.attributes?.procedure?.data
      ? [
          {
            menuItem: `${t.procedures.add}`,
            icon: (
              <ProcedureDetailsIcon
                color={activeIndex === 1 ? "#0C66E4" : "#788CA5"}
              />
            ),
            pane: {
              key: "procedure-items",
              content: (
                <div className="pane-body">
                  <ProcedureItems
                    targetedData={
                      data?.attributes?.report?.data || {
                        attributes: { fields: [] },
                      }
                    }
                    procedureData={data?.attributes?.procedure}
                    procedureItemsCount={
                      data?.attributes?.procedure?.data?.attributes
                        ?.procedureItems
                    }
                    WOStatus={
                      data?.attributes?.status === "completed" ||
                      data?.attributes?.status === "inReview"
                    }
                  />
                </div>
              ),
            },
          },
        ]
      : []),
    {
      menuItem: `${t.parts.tab.first}`,
      icon: (
        <PartDetailIcon
          color={
            activeIndex === (data?.attributes?.procedure?.data ? 2 : 1)
              ? "#0C66E4"
              : "#788CA5"
          }
        />
      ),
      pane: {
        key: "labor",
        content: (
          <div className="pane-body">
            <Part
              data={data}
              modalWidth={modalWidth}
              setData={setData}
              setActiveIndex={setActiveIndex}
              negative={negative}
              untoggle={untoggle}
              setModalWidth={setModalWidth}
              setRefresh={setRefresh}
              refresh={refresh}
              setSearchTitle={setSearchTitle}
              setSelectedDates={setSelectedDates}
              setFilterOptions={setFilterOptions}
            />
          </div>
        ),
      },
    },
    // Labor tab (adjust index dynamically)
    {
      menuItem: `${t.workOrders.form.labor_title}`,
      icon: (
        <LaborIcon
          color={
            activeIndex === (data?.attributes?.procedure?.data ? 3 : 2)
              ? "#0C66E4"
              : "#788CA5"
          }
        />
      ),
      pane: {
        key: "labor",
        content: (
          <div className="pane-body">
            <Labor
              data={data}
              modalWidth={modalWidth}
              setData={setData}
              setActiveIndex={setActiveIndex}
              negative={negative}
              untoggle={untoggle}
              setModalWidth={setModalWidth}
              setRefresh={setRefresh}
              refresh={refresh}
              setSearchTitle={setSearchTitle}
              setSelectedDates={setSelectedDates}
              setFilterOptions={setFilterOptions}
            />
          </div>
        ),
      },
    },
    {
      menuItem: `${t.workOrders.cost.cost}`,
      icon: (
        <CostIcon
          color={
            activeIndex === (data?.attributes?.procedure?.data ? 4 : 3)
              ? "#0C66E4"
              : "#788CA5"
          }
        />
      ),
      pane: {
        key: "cost",
        content: (
          <div className="pane-body">
            <Cost
              data={data}
              modalWidth={modalWidth}
              setData={setData}
              setActiveIndex={setActiveIndex}
              negative={negative}
              untoggle={untoggle}
              setModalWidth={setModalWidth}
              setRefresh={setRefresh}
              refresh={refresh}
              setSearchTitle={setSearchTitle}
              setSelectedDates={setSelectedDates}
              setFilterOptions={setFilterOptions}
            />
          </div>
        ),
      },
    },

    // Comments tab (always the last tab)
    {
      menuItem: `${t.workOrders.activityTab.activity}`,
      icon: (
        <LogIcon
          color={
            activeIndex === (data?.attributes?.procedure?.data ? 5 : 4)
              ? "#0C66E4"
              : "#788CA5"
          }
        />
      ),
      pane: {
        key: "comment",
        content: (
          <div className="pane-body">
            <Comment data={data} />
          </div>
        ),
      },
    },
  ];

  const deleteHandler = async () => {
    const response = await deleteWorkOrder(targetedData.id, deleteSeries);

    if (response.status == 200) {
      toast.success(t.workOrders.form.deleted.msg);
      setFilterOptions({
        serialNumber: "",
        people: [],
        teams: [],
        customers: [],
        locations: [],
        createdByUser: [],
        status: [],
        priority: [],
        categories: [],
      });
      setSearchTitle("");
      toggleDeleteSuccessModal("close");
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      setDeleteSeries(false);
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.workOrders.details_view.delete_not_allowed_message);
      toggleDeleteSuccessModal();
      setDeleteSeries(false);
    } else {
      toast.error(t.common.something_wrong);
      setDeleteSeries(false);
    }
  };

  /* The function `downloadXLS` fetches WO data, formats it, and downloads it as an Excel file. */
  const downloadXLS = () => {
    let csvData = [];

    let workOrder_data = [];
    workOrder_data.id = data?.id;
    workOrder_data.title = data?.attributes?.title || t.common.na;
    workOrder_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    const workOrderStatus = statusOptions.find(
      (status) => status.value === data?.attributes?.status
    );
    workOrder_data.status = workOrderStatus?.label || t.common.na;

    workOrder_data.priority =
      humanize(data?.attributes?.priority) || t.common.na;
    let assigneePeople = data?.attributes?.people?.data;
    let assignString = "";

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map(
          (people) =>
            `${people?.attributes?.firstName || ""} ${people.attributes
              .lastName || ""}`
        )
        .join(", ");
    } else {
      assignString = t.common.na;
    }
    workOrder_data.assignee = assignString;

    const categoryStatus = categoryOptions.find(
      (one) => one.value == data?.attributes?.category
    );
    workOrder_data.category = categoryStatus?.label || t.common.na;

    const codeStatus = codeOptions?.find(
      (one) => one.value == data.attributes.code
    );
    workOrder_data.code = codeStatus?.label || t.common.na;
    workOrder_data.customer = data?.attributes?.customer?.data?.attributes?.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    workOrder_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    workOrder_data.procedure = data.attributes?.procedure?.data?.attributes
      ?.title
      ? data.attributes?.procedure?.data?.attributes?.title
      : t.common.na;
    let partsData = data.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    workOrder_data.parts = partsString;

    workOrder_data.estimationTime = data.attributes?.estimationTime
      ? `${data.attributes?.estimationTime}` + " Hour"
      : t.common.na;

    workOrder_data.dueDate =
      moment(data?.attributes?.dueDate).format("DD/MM/YYYY LT") || t.common.na;

    workOrder_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    workOrder_data.createdAt =
      moment(data.attributes.createdAt).format("DD/MM/YYYY LT") || t.common.na;

    csvData.push([
      workOrder_data.id,
      workOrder_data.title,
      workOrder_data.description,
      workOrder_data.status,
      workOrder_data.priority,
      workOrder_data.assignee,
      workOrder_data.category,
      workOrder_data.code,
      workOrder_data.customer,
      workOrder_data.location,
      workOrder_data.procedure,
      workOrder_data.estimationTime,
      workOrder_data.dueDate,
      workOrder_data.createdByUser,
      workOrder_data.createdAt,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Title column
      { wch: 35 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      data?.attributes?.title || t.workOrders.details_view.work_order_no
    );
  };

  /*
   * The function `generatePDF` fetches WO data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = () => {
    let csvData = [];

    let workOrder_data = [];
    workOrder_data.id = data?.id;
    workOrder_data.title = data?.attributes?.title || t.common.na;
    workOrder_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    const workOrderStatus = statusOptions.find(
      (status) => status.value === data?.attributes?.status
    );
    workOrder_data.status = workOrderStatus?.label || t.common.na;

    workOrder_data.priority =
      humanize(data?.attributes?.priority) || t.common.na;
    let assigneePeople = data?.attributes?.people?.data;
    let assignString = "";

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map(
          (people) =>
            `${people?.attributes?.firstName || ""} ${people.attributes
              .lastName || ""}`
        )
        .join(", ");
    } else {
      assignString = t.common.na;
    }
    workOrder_data.assignee = assignString;

    const categoryStatus = categoryOptions.find(
      (one) => one.value == data?.attributes?.category
    );
    workOrder_data.category = categoryStatus?.label || t.common.na;

    const codeStatus = codeOptions?.find(
      (one) => one.value == data.attributes.code
    );
    workOrder_data.code = codeStatus?.label || t.common.na;
    workOrder_data.customer = data?.attributes?.customer?.data?.attributes?.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    workOrder_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    workOrder_data.procedure = data.attributes?.procedure?.data?.attributes
      ?.title
      ? data.attributes?.procedure?.data?.attributes?.title
      : t.common.na;
    let partsData = data.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    workOrder_data.parts = partsString;

    workOrder_data.estimationTime = data.attributes?.estimationTime
      ? `${data.attributes?.estimationTime}` + " Hour"
      : t.common.na;

    workOrder_data.dueDate =
      moment(data?.attributes?.dueDate).format("DD/MM/YYYY LT") || t.common.na;

    workOrder_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    workOrder_data.createdAt =
      moment(data.attributes.createdAt).format("DD/MM/YYYY LT") || t.common.na;

    csvData.push([
      workOrder_data.id,
      workOrder_data.title,
      workOrder_data.description,
      workOrder_data.status,
      workOrder_data.priority,
      workOrder_data.assignee,
      workOrder_data.category,
      workOrder_data.code,
      workOrder_data.customer,
      workOrder_data.location,
      workOrder_data.procedure,
      workOrder_data.estimationTime,
      workOrder_data.dueDate,
      workOrder_data.createdByUser,
      workOrder_data.createdAt,
    ]);

    const columnWidths = [10, 25, 25, 20, 15, 50, 22, 15, 20, 25, 25, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.title || t.workOrders.details_view.work_order_no,
      columnWidths,
      "A2"
    );
  };

  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    const currentPathname = window.location.pathname;
    if (data) {
      untoggle();
      setActiveIndex(0);
      setModalWidth(Math.max(window.innerWidth / 2, 633));
      setRefresh(!refresh);

      if (currentPathname == "/work-orders") {
        setSearchTitle("");
        setSelectedDates([]);
        setFilterOptions({
          serialNumber: "",

          people: [],
          teams: [],
          customers: [],
          locations: [],
          createdByUser: [],

          status: [],
          priority: [],
          categories: [],
        });
      }
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"633px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.workOrders.details_view.work_order_no} #
                {data?.attributes?.countId}
              </div>

              <div className="flexbox align-center workorder-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={
                    userPermissions?.["work-order"]?.delete &&
                    targetedData?.attributes?.status == "open"
                  }
                  isEdit={
                    (userPermissions?.["work-order"]?.edit ||
                      checkForPermission(
                        targetedData?.attributes?.createdByUser?.data?.id
                      )) &&
                    targetedData?.attributes?.status !== "completed" &&
                    targetedData?.attributes?.status !== "inProgress" &&
                    targetedData?.attributes?.status !== "cancelled"
                  }
                />
                <div
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                    setActiveIndex(0);
                    setModalWidth(Math.max(window.innerWidth / 2, 633));
                  }}
                >
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>

                  <CloseIcon />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "13px 0px",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            className="body"
          >
            {data ? (
              <Grid className="p-0">
                <Grid.Column style={{ flexGrow: 1 }} className="p-0 ">
                  <div
                    style={
                      selectedLanguage == "en"
                        ? { padding: "16px 0px 16px 9px" }
                        : { padding: "16px 11px 16px 0px" }
                    }
                    className="pane-content mt-5"
                  >
                    {panes[activeIndex]?.pane?.content}
                  </div>
                </Grid.Column>
                <Grid.Column
                  style={{ width: "100px", flex: "0 0 100px" }}
                  className={
                    selectedLanguage == "en"
                      ? "sidebar ml-0 pl-0"
                      : "sidebar mr-0 pr-0"
                  }
                >
                  <Menu
                    className={` ${
                      selectedLanguage == "en" ? "pl-0 ml-0" : "pr-0 mr-0"
                    } side-item-menu`}
                    fluid
                    icon="labeled"
                    vertical
                    tabular
                  >
                    {panes.map((pane, index) => (
                      <Menu.Item
                        key={pane.pane.key}
                        name={pane.menuItem}
                        active={activeIndex === index}
                        onClick={() => setActiveIndex(index)}
                        className={`itembar-menu ${index == 0 ? "" : ""}`}
                      >
                        <div
                          className={` ${
                            activeIndex === index ? "items-menu" : ""
                          }`}
                        >
                          {pane.icon}
                        </div>
                        <div className="mb-4">{pane.menuItem}</div>
                      </Menu.Item>
                    ))}
                  </Menu>
                </Grid.Column>
              </Grid>
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
          setActiveIndex(0);
          setModalWidth(Math.max(window.innerWidth / 2, 633));
        }}
      ></div>

      {/* Delete Permission Modal */}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workOrders.details_view.work_order_no}
      >
        <span>{t.workOrders.form.delete.msg}</span>
      </DeletePermissionModal>

      {/* Delete Result Modal */}
      <DeleteResultModal
        closeCallback={() => {
          toggleDeleteSuccessModal(deleteErrorMessage ? "" : "close");
        }}
        deleteErrorMessage={deleteErrorMessage}
      >
        <div className="mb-2">
          {deleteErrorMessage ? <Close /> : <Success />}
        </div>
        <p className="status-modal-popup-message">
          {deleteErrorMessage
            ? t.workOrders.details_view.delete_not_allowed_message
            : t.workOrders.form.deleted.msg}
        </p>
      </DeleteResultModal>
      {/* series edit modal */}
      <Modal
        size="tiny"
        className="assets-modal"
        open={seriesModal}
        onClose={() => {
          setSeriesModal(false);
          setSeriesEdit(false);
        }}
        style={{ minHeight: "191px", maxWidth: "472px" }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.form_title.edit}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setSeriesModal(false);
                setSeriesEdit(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <p
            className="assets-label ml-1 mt-2  "
            style={{ lineHeight: "10px" }}
          >
            {t.workOrders.WO_edit}
          </p>
          <p className="assets-label ml-1" style={{ lineHeight: "10px" }}>
            {t.workOrders.what_edit}
          </p>
          <div className="d-flex flex-column mt-8 ml-3 radio-text">
            <div className="d-flex">
              <Radio
                // label="This work order "
                name="radioGroup"
                className="wo-radio"
                value={seriesEdit ? "false" : "true"}
                checked={!seriesEdit}
                onChange={() => {
                  setSeriesEdit(false);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {" "}
                {t.workOrders.this_wo}
              </label>
            </div>
            {!["inReview", "onHold"].includes(
              targetedData?.attributes?.status
            ) ? (
              <div className="d-flex mt-3">
                <Radio
                  className="wo-radio"
                  name="radioGroup"
                  value={seriesEdit ? "true" : "false"}
                  checked={seriesEdit}
                  onChange={() => {
                    setSeriesEdit(true);
                  }}
                />
                <label className="radio-text ml-3 mr-3">
                  {" "}
                  {t.workOrders.this_series}
                </label>
              </div>
            ) : null}
          </div>
        </Modal.Content>
        <div
          className={`modal-button-container ${
            selectedLanguage == "en" ? "mr-8" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setSeriesModal(false);
              setSeriesEdit(false);
              // closeCallback();
            }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => {
              setSeriesModal(false);

              edit(seriesEdit);
            }}
            className="modal-form-action-button light-blue"
          >
            {t.common.edit}
          </button>
        </div>
      </Modal>
      {/* series Delete modal*/}
      <Modal
        size="tiny"
        className="assets-modal"
        open={deleteSeriesModal}
        onClose={() => {
          setDeleteSeriesModal(false);
          setDeleteSeries(false);
        }}
        style={{ minHeight: "191px", maxWidth: "472px" }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.form_title.delete_small}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setDeleteSeriesModal(false);
                setDeleteSeries(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <p
            className="assets-label ml-1 mt-2  "
            style={{ lineHeight: "10px" }}
          >
            {t.workOrders.WO_edit}
          </p>
          <p className="assets-label ml-1" style={{ lineHeight: "10px" }}>
            {t.workOrders.what_delete}
          </p>
          <div className="d-flex flex-column mt-8 ml-3 radio-text">
            <div className="d-flex">
              <Radio
                name="radioGroup"
                className="wo-radio"
                value={deleteSeries ? "false" : "true"}
                checked={!deleteSeries}
                onChange={() => {
                  setDeleteSeries(false);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {" "}
                {t.workOrders.this_wo}
              </label>
            </div>

            <div className="d-flex mt-3">
              <Radio
                className="wo-radio"
                name="radioGroup"
                value={deleteSeries ? "true" : "false"}
                checked={deleteSeries}
                onChange={() => {
                  setDeleteSeries(true);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {" "}
                {t.workOrders.this_series}
              </label>
            </div>
          </div>
        </Modal.Content>
        <div
          className={`modal-button-container ${
            selectedLanguage == "en" ? "mr-8" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setDeleteSeriesModal(false);
              setDeleteSeries(false);
            }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => {
              setDeleteSeriesModal(false);
              deleteHandler();
            }}
            className="modal-form-action-button light-blue"
          >
            {t.common.delete}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Preview;
