import React, { useState, useEffect, useRef } from "react";
import { Tab, Loader, Dimmer } from "semantic-ui-react";

import { deleteLocation } from "../../../config/functions";

import Details from "./details";
import Orders from "./orders";
import Gmap from "./gmap";
import SubLocations from "./sub-locations";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { useDeleteResultModal } from "../../../../hooks/Messages/useDeleteResultModal";
import { toast } from "react-toastify";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { useDeletePermissionModal } from "../../../../hooks/Messages/useDeletePermissionModal";
import { Close, CloseIcon, Success } from "../../../../Assets/Icons/svg";
import { Country, State } from "country-state-city";
import moment from "moment";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import { Resizable } from "re-resizable";
import DetailsViewOptions from "../../../components/common/DetailsViewOptions";
import ReactTooltip from "react-tooltip";
function Preview({
  toggled,
  untoggle,
  edit,
  targetedData,
  refresh,
  setRefresh,
  setModalWidth,
  modalWidth,
}) {
  const t = useSelector(selectTranslations);

  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setTeams(targetedData.attributes.teams);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      let execlude_ids = ["popupTrigger"];

      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
    });
  }, [popUpRef]);

  const headers = [
    { label: t.locations.table.ID, key: "id" },
    { label: t.locations.form.location_name, key: "name" },
    { label: t.locations.table.address, key: "address" },
    { label: t.locations.table.country, key: "country" },
    { label: t.locations.table.city, key: "city" },
    { label: t.locations.table.customer, key: "customer" },
    { label: t.locations.form.vendors, key: "vendors" },
    { label: t.locations.form.people, key: "people" },
    { label: t.locations.form.teams, key: "teams" },
    { label: t.locations.form.propertyType, key: "propertyType" },
    { label: t.locations.table.createdByUser, key: "createdBy" },
    { label: t.procedures.table.createdAt, key: "createdAt" },
  ];

  const downloadXLS = () => {
    let csvData = [];

    let location_data = [];

    location_data.id = data.id;
    location_data.name = data.attributes.name || t.common.na;
    location_data.address = data?.attributes?.address
      ? data.attributes.address
      : t.common.na;
    location_data.country = data?.attributes?.country
      ? Country.getCountryByCode(data.attributes.country).name
      : t.common.na;
    location_data.city = data?.attributes?.city
      ? State.getStateByCodeAndCountry(
          data?.attributes?.city,
          data?.attributes?.country
        ).name
      : t.common.na;

    let customerData = data.attributes?.customers?.data;
    let customerString = "";

    if (customerData?.length > 0) {
      customerString = customerData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      customerString = t.common.na;
    }

    location_data.customer = customerString;

    let vendorData = data.attributes?.vendors?.data;
    let vendorString = "";

    if (vendorData?.length > 0) {
      vendorString = vendorData.map((each) => each.attributes?.name).join(", ");
    } else {
      vendorString = t.common.na;
    }

    location_data.vendor = vendorString;

    let peopleData = data.attributes?.users?.data;
    let peopleString = "";

    if (peopleData?.length > 0) {
      peopleString = peopleData
        .map(
          (each) => each.attributes?.firstName + " " + each.attributes?.lastName
        )
        .join(", ");
    } else {
      peopleString = t.common.na;
    }

    location_data.people = peopleString;

    const locationData = data?.attributes?.propertyType;
    let displayText;

    if (locationData && Array.isArray(locationData)) {
      displayText = locationData
        .map((location) => {
          const nameParts = location?.property;
          return nameParts;
        })
        .join(", "); // Adjust the join character as needed
    } else {
      displayText = t.common.na;
    }

    let teamsData = data.attributes?.teams?.data;
    let teamsString = "";

    if (teamsData?.length > 0) {
      teamsString = teamsData.map((each) => each.attributes.name).join(", ");
    } else {
      teamsString = t.common.na;
    }

    location_data.teams = teamsString;

    location_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    location_data.createdAt =
      moment(data.attributes.createdAt).format("DD/MM/YYYY LT") || t.common.na;

    csvData.push([
      location_data.id,
      location_data.name,
      location_data.address,
      location_data.country,
      location_data.city,
      location_data.customer,
      location_data.vendor,
      location_data.people,
      location_data.teams,
      displayText ? displayText : t.common.na,
      location_data.createdByUser,
      location_data.createdAt,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.locations.title
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let location_data = [];
    const locationData = data?.attributes?.propertyType;
    let displayText;

    if (locationData && Array.isArray(locationData)) {
      displayText = locationData
        .map((location) => {
          const nameParts = location?.property;
          return nameParts;
        })
        .join(", "); // Adjust the join character as needed
    } else {
      displayText = t.common.na;
    }

    location_data.id = data.id;
    location_data.name = data.attributes.name || t.common.na;
    location_data.address = data?.attributes?.address
      ? data.attributes.address
      : t.common.na;
    location_data.country = data?.attributes?.country
      ? Country.getCountryByCode(data.attributes.country).name
      : t.common.na;
    location_data.city = data?.attributes?.city
      ? State.getStateByCodeAndCountry(
          data?.attributes?.city,
          data?.attributes?.country
        ).name
      : t.common.na;

    let customerData = data.attributes?.customers?.data;
    let customerString = "";

    if (customerData?.length > 0) {
      customerString = customerData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      customerString = t.common.na;
    }

    location_data.customer = customerString;

    let vendorData = data.attributes?.vendors?.data;
    let vendorString = "";

    if (vendorData?.length > 0) {
      vendorString = vendorData.map((each) => each.attributes?.name).join(", ");
    } else {
      vendorString = t.common.na;
    }

    location_data.vendor = vendorString;

    let peopleData = data.attributes?.users?.data;
    let peopleString = "";

    if (peopleData?.length > 0) {
      peopleString = peopleData
        .map(
          (each) => each.attributes?.firstName + " " + each.attributes?.lastName
        )
        .join(", ");
    } else {
      peopleString = t.common.na;
    }

    location_data.people = peopleString;

    let teamsData = data.attributes?.teams?.data;
    let teamsString = "";

    if (teamsData?.length > 0) {
      teamsString = teamsData.map((each) => each.attributes.name).join(", ");
    } else {
      teamsString = t.common.na;
    }

    location_data.teams = teamsString;

    location_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    location_data.createdAt =
      moment(data.attributes.createdAt).format("DD/MM/YYYY LT") || t.common.na;

    csvData.push([
      location_data.id,
      location_data.name,
      location_data.address,
      location_data.country,
      location_data.city,
      location_data.customer,
      location_data.vendor,
      location_data.people,
      location_data.teams,
      displayText ? displayText : t.common.na,
      location_data.createdByUser,
      location_data.createdAt,
    ]);

    const columnWidths = [];
    DownloadPdfFileWithData(
      headers,
      csvData,
      t.locations.add,
      columnWidths,
      "A2"
    );
  };

  const handleEdit = () => {
    edit();
    setPopupIsOpen(false);
  };

  const handleDelete = () => {
    setDeletePermissionModal(true);
    setDeleteModal(true);
    setPopupIsOpen(false);
  };

  const panes = data?.attributes.isParent
    ? [
        {
          menuItem: t.locations.form.details,
          pane: {
            key: "details",
            content: (
              <div className="pane-body">
                <Details data={data} />
              </div>
            ),
          },
        },
        {
          menuItem: t.locations.form.sub_locations,
          pane: {
            key: "subLocations",
            content: (
              <div className="pane-body">
                <SubLocations data={data} />
              </div>
            ),
          },
        },
        {
          menuItem: t.locations.form.map,
          pane: {
            key: "map",
            content: (
              <div className="pane-body">
                <Gmap data={data} reload={() => setRefresh(!refresh)} />
              </div>
            ),
          },
        },
        // {
        //   menuItem: t.locations.form.assignees,
        //   pane: {
        //     key: 'assignees',
        //     content: (
        //       <div className="pane-body">
        //         <Assignees teams={teams} data={data} />
        //       </div>
        //     ),
        //   },
        // },

        {
          menuItem: t.locations.form.work_orders,
          pane: {
            key: "orders",
            content: (
              <div className="pane-body">
                <Orders orders={[]} data={data} />
              </div>
            ),
          },
        },
        // {
        //   menuItem: 'WOs',
        //   pane: {
        //     key: 'WOs',
        //     content: (
        //       <div className="pane-body">
        //         <WorkOrder orders={[]} data={data} />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: t.workOrders.details_view.log,
        //   pane: {
        //     key: 'log',
        //     content: (
        //       <div className="pane-body">
        //         <Log targetedData={data} />
        //       </div>
        //     ),
        //   },
        // },
      ]
    : [
        {
          menuItem: t.locations.form.details,
          pane: {
            key: "details",
            content: (
              <div className="pane-body">
                <Details data={data} />
              </div>
            ),
          },
        },
        // {
        //   menuItem: t.locations.form.assignees,
        //   pane: {
        //     key: 'assignees',
        //     content: (
        //       <div className="pane-body">
        //         <Assignees teams={teams} data={data} />
        //       </div>
        //     ),
        //   },
        // },
        {
          menuItem: t.locations.form.work_orders,
          pane: {
            key: "orders",
            content: (
              <div className="pane-body">
                <Orders orders={[]} data={data} />
              </div>
            ),
          },
        },
        // {
        //   menuItem: 'WOs',
        //   pane: {
        //     key: 'WOs',
        //     content: (
        //       <div className="pane-body">
        //         <WorkOrder orders={[]} data={data} />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: t.workOrders.details_view.log,
        //   pane: {
        //     key: 'log',
        //     content: (
        //       <div className="pane-body">
        //         <Log targetedData={data} />
        //       </div>
        //     ),
        //   },
        // },
      ];

  const deleteHandler = async () => {
    const response = await deleteLocation(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      toggleDeleteSuccessModal("close");
      toast.success(
        data?.attributes.isParent
          ? t.locations.delete_location_success
          : t.locations.delete_sublocation_success
      );
    } else if (response.status == 400) {
      toggleDeleteSuccessModal();
      toast.error(t.locations.related_location_error);
      setDeleteErrorMessage(true);
    } else {
      toast.error(t.common.something_went_wrong);
    }
  };

  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      setData();
      untoggle();
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? "aside-modal preview active procedure"
            : "aside-modal preview procedure"
        }
      >
        {" "}
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {data?.attributes.isParent
                  ? `${t.locations.location} #${data?.attributes?.countId}`
                  : `${t.locations.form.sub_location}  #${data?.attributes?.countId}`}
              </div>

              <div className="flexbox align-center detail-header-buttons">
                <DetailsViewOptions
                  generatePDF={generatePDF}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  isDelete={userPermissions?.location?.delete}
                  isEdit={
                    userPermissions?.location?.edit ||
                    checkForPermission(
                      targetedData?.attributes?.createdByUser?.data?.id
                    )
                  }
                />

                <span
                  data-tip
                  data-for={`tooltip-close`}
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == "en" ? "ml-1" : "mr-3"
                  }`}
                  onClick={() => {
                    untoggle();
                  }}
                >
                  <CloseIcon />
                  <ReactTooltip
                    id={`tooltip-close`}
                    place="bottom"
                    effect="solid"
                  >
                    {t.common.close}
                  </ReactTooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {data ? (
              <Tab
                panes={panes}
                renderActiveOnly={false}
                className={data?.attributes.isParent ? "location-tab" : ""}
                style={
                  data?.attributes.isParent ? { paddingBottom: "16px" } : {}
                }
              />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>{" "}
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => {
          setData();
          untoggle();
        }}
      ></div>

      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={
          data?.attributes.isParent
            ? t.locations.add
            : t.locations.form.sub_location
        }
      >
        <span>
          {" "}
          {data?.attributes.isParent
            ? t.locations.delete_location_statement
            : t.locations.delete_sublocation_statement}
        </span>
      </DeletePermissionModal>

      <DeleteResultModal
        closeCallback={() => {
          toggleDeleteSuccessModal(deleteErrorMessage ? "" : "close");
        }}
        deleteErrorMessage={deleteErrorMessage}
      >
        <div className="mb-2">
          {deleteErrorMessage ? <Close /> : <Success />}
        </div>
        <p className="status-modal-popup-message">
          {deleteErrorMessage
            ? t.locations.related_location_error
            : data?.attributes.isParent
            ? t.locations.delete_location_success
            : t.locations.delete_sublocation_success}
        </p>
      </DeleteResultModal>
    </>
  );
}

export default Preview;
