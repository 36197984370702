export default {
  title: "Work Orders",
  title_small: "work orders",
  create_WO: "Add Work Order",
  update_date: "Date Updated",
  tabs: {
    first: "List View",
    second: "Calendar View",
  },
  add: "Order",
  small_cancel: "cancel",
  kanbar: "Kanban View",
  upload_QR: "Upload QR code",
  drag_img: "Upload image or video",
  upload_a_file: "Upload a file",
  actual_times: "Actual Time",
  actual_cost: "Actual Cost",
  add_assignee: "Add Assignee",
  add_assignees: "Add Assignees",
  add_team: "Add Team",
  filter: {
    search: "Search",
    assignees: "Assignees",
    locations: "Locations",
    dueDate: "WO date",
    date: "Date",
    status: "Status",
    priority: "Priority",
    createdByUser: "Created By",
    estimation_time_by: "Estimated Time",
    teams: "TEAMS",
    people: "PEOPLE",
    select_assignees: "SELECT ASSIGNEES",
    select_locations: "SELECT LOCATIONS",
    filter_by_status: "FILTER BY STATUS",
    filter_by_priority: "FILTER BY PRIORITY",
    select_people: "SELECT PEOPLE",
  },

  activityTab: {
    activity: "Activity",
  },
  form: {
    added_parts_set: "Added Part & Sets",
    validation: {
      required_field: "Field is required",
    },
    additional_cost: "Additional Cost",
    part_qty_update: "Part quantity updated successfully",
    set_qty_update: "Set quantity updated successfully",
    start_date_new: "Start Date/ Time",
    due_date_new: "Due Date/ Time",
    primary: "Primary Assignee",
    secondary: "Secondary Assignees",
    secondary_select: "Select secondary assignees",
    primary_select: "Select primary assignees",
    close_out: "Close Out Notes",
    add_close_out: "Add Close out Notes",
    labor_title: "Labor",
    select_assignees: "Select assignee",
    sub_total: "Total",
    part_set: "Parts & Sets",
    add_part_set: "Add Parts & Sets",
    search_parts: "Search parts & sets",
    form_title: {
      add: "Add Work Order",
      edit: "Edit Work Order",
      edit_small: "Edit work order",
      delete_small: "Delete Work Order",
    },
    select_part: "Select parts",
    select_set: "Select sets",
    title: "Title",
    title_name: "Add title",
    progress: "Progress",
    description: "Description",
    add_people_tooltip:
      "Must assign a Primary Assignee before adding Secondary Assignees.",
    description_text: "Add description",
    customer: "Customers",
    customer_placeholder: "Select Customer",
    location: "Location",
    location_placeholder: "Select Location",
    order_type: "Order Type",
    add_order_type: "Select order type",
    order_type_1: "Reactive (Non-Planned)",
    order_type_2: "Scheduled (Planned)",
    due_date: "Due Date",
    add_due_date: "Select due date",
    include_due_time: "Include Due Time?",
    include_start_time: "Include Start Time?",
    due_time: "Due Time",
    add_due_time: "Add due time",
    priority: "Priority",
    priority_options: ["None", "Low", "Medium", "High"],
    people: "People",
    people_placeholder: "Select People",
    time_placeholder: "Add estimated time",
    cost_placeholder: "Add estimated cost",
    start_time_date: "Start Date/Time",
    teams: "Teams",
    teams_placeholder: "Select Teams",
    assets: "Assets",
    assets_placeholder: "Select Assets",
    create_QR: "Create QR Code",
    create_QR_for_WO: "Create QR Code for this work order",
    category: "Category / Code",
    code: "Code",
    add_code: "Select code",
    file: "File",
    attach_file: "Attach a file to this work order",
    instances: "Instances",
    category_options: [
      "Damage",
      "Corrective action Downtime",
      "Planned Downtime",
      "Unplanned Downtime",
      "Inspection",
      "Electrical",
      "Mechanical",
      "HVAC",
      "Preventive maintenance",
      "Project",
      "Safety",
      "SOP",
    ],

    // "damage",
    // "corrective action Downtime",
    // "planned Downtime",
    // "unplanned Downtime",
    // "inspection",
    // "electrical",
    // "mechanical",
    // "HVAC",
    // "preventive maintenance",
    // "project",
    // "safety",
    // "sop"
    teams: "Teams",
    date_created: "Date Created",
    teams_placeholder: "Select Teams",
    parts: "Parts Inventory",
    parts_placeholder: "Select Parts",
    labor_cost: "Estimated Cost",
    add_labor_cost: "Add estimated cost",
    added_cost: "Added Cost",
    added_parts: "Added Parts",
    procedure: "Procedure",
    procedure_placeholder: "Select Procedure",
    attachments: "Attachments",
    attachments_placeholder: "Add Files",
    attachments_allowed: "Allowed types",
    attachments_remove: ["Remove file?", "Keep", "Remove"],
    submit: {
      add: "Submit",
      edit: "Update",
    },
    cancel_button: "Cancel",
    success: {
      msg: "Work order added successfully",
      ok: "Ok",
    },
    cancel: {
      msg: "Are you sure you want to cancel?",
      cancel: "Yes, cancel",
      keep: "Keep",
    },
    updated: {
      msg: "Work order updated successfully",
      ok: "Ok",
    },
    delete: {
      msg: "Are you sure want to delete this work order?",
      keep: "Keep",
      delete: "Delete",
    },
    deleted: {
      msg: "WO deleted successfully",
      ok: "OK",
    },
  },

  labor: {
    success: "Time added successfully",
    edit: "Time updated successfully",
    delete: "Time deleted successfully",
    delete_labor: "Are you sure you want to delete this time?",
    add_time: "Add Time",
    edit_time: "Edit Time",
    delete_time: "Time",
    total_time: "Total Time",
    total_cost: "Total Cost",
    statDate: "Start Date/Time",
    duration: "Duration",
    started_at: "Started at",
    drive_time: "Drive Time",
    preparation_time: "Preparation Time",
    inspection_time: "Inspection Time",
    testing_time: "Testing and Verification Time",
    downtime_time: "Downtime/Waiting Time",
    wrench_time: "Wrench Time",
    other_time: "Other Time",
  },

  cost: {
    cost: "Cost",
    partsCosts: "Parts & Sets Costs",
    laborCosts: "Labor Costs",
    addCost: "Additional Costs",
    add_cost: "Add Cost",
    success: "Cost added successfully",
    edit: "Cost updated successfully",
    delete: "Cost deleted successfully",
    delete_cost: "Are you sure you want to delete this cost?",
    assigned: "Assigned to",
    edit_cost: "Edit Cost",
    date_time: "Date & Time",
    labor_cost: "Labor Cost",
    part_cost: "Part Cost",
    travel_cost: "Travel Cost",
    tax: "Tax",
    other_cost: "Other Cost",
  },
  table: {
    title: "Title",
    ID: "ID",
    status: "Status",
    priority: "Priority",
    add_priority: "Select priority",
    assignees: "Assignees",
    assignee: "Assignee",
    category: "Category",
    customer: "Customers",
    location: "Location",
    dueDate: "Due Date",
    procedure: "Procedure",
    createdByUser: "Created By",
    createdAt: "Created At",
    updatedAt: "Updated At",
    resultNotFound: "Result Not Found",
  },

  procedure_modal: {
    title: "Procedure Modification",
    sub_title:
      "You are about to change the procedure attached to this work order. Some fields in the current procedure have already been worked on by assignees.",
    important: "Important",
    sub_title1:
      "Changing the procedure will reset or remove any progress or data entered in the current checklist.",
    sub_title2: "This action cannot be undone.",
    proceed: "Do you wish to proceed?",
    confirm: "Confirm",
  },
  details_view: {
    pastWO: "Past work orders",
    upcomingWO: "Up next work orders",
    view_schedual: "View Schedual",
    view_instance: "Show Instances",
    work_order_no: "Work Order",
    work_order: "work order",
    details: "Details",
    created_by: "Created by",
    priority: "Priority",
    status: "Status",
    due_date: "Due Date",
    estimation_time: "Estimated Time",

    customer: "Customers",
    location: "Location",
    order_type: "Order Type",
    repeat: "Repeat Every",
    category: "Category",
    procedure: "Procedure",
    parts: "Parts",
    assets: "Assets",
    edit: "Edit",
    delete: "Delete",
    assignees: "Assignees",
    attachments: "Attachments",
    comments: "Comments",
    reports: "Reports",
    labor_cost: "Labor Cost",
    no_attachments_statement: "No attachments belong to work order",
    no_comments_statement: "No comment available",
    no_log_statement: "No log available",
    name: "Name",
    fileType: "Type",
    size: "Size",
    view: "View",
    approve: "Approve",
    decline: "Decline",
    schedule: "Schedule",
    daily: "Day/s",
    weekly: "Week/s",
    monthly: "Month/s",
    yearly: "Year/s",
    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    past_due_statement: "Past due since",
    procedure_items: "Procedure Items",
    delete_not_allowed_message: "This Work Order cannot be deleted ",
    expire_after: "Ends After",
    expire_on: "Ends on",
    orders: "Orders",
    every: "every",
    week: "Week",
    month: "Month",
    year: "Year",
    weeks: "Weeks",
    months: "Months",
    years: "Years",
    on: "On",
    after: "After",
    hour: "Hour",
    log: "Log",
    start_date: "Start Date",
    start_time: "Start Time",
    repeat_on: "Repeat On",
    never: "Never",
    ends: "End Schedule",
    end_date: "End Date",
    repeat_every: "Repeat",
    every_day: "Every",
    week_on: "Weeks on",
    month_on: "Month, on the",
    select_no: "Select number",
    select_day: "Select day",
  },
  work_order_status: {
    open: "Open",
    open_status_action: "Open",
    reOpen: "Reopen",
    open_status: "open",
    inProgress: "In-Progress",
    onHold: "On-Hold",
    close: "Close",
    inReview: "Completed",
    completed: "Closed",
    hold: "Hold",
    cancel: "Cancelled",
    cancelled: "Cancelled",
  },
  modal_cancel: "Cancel work order",
  modal_progress: "In-Progress work order",
  modal_hold: "Hold work order",
  modal_approve: "Approve work order",
  modal_decline: "Decline work order",
  priority_status: {
    none: "None",
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  work_order_category: {
    Damage: "Damage",
    Electrical: "Electrical",
    Inspection: "Inspection",
    Mechanical: "Mechanical",
    Preventive: "Preventive",
    Project: "Project",
    Refrigeration: "Refrigeration",
    HVAC: "HVAC",
    Safety: "Safety",
    Sop: "Standard Operating Procedure",
  },
  due_date_status: {
    any_date: "Any Date",
    today: "Today",
    tomorrow: "Tomorrow",
    next_7_day: "Next 7 Days",
    next_30_day: "Next 30 Days",
    this_month: "This Month",
    this_year: "This Year",
    yesterday: "Yesterday",
    last_week: "Last Week",
    last_month: "Last Month",
    last_year: "Last Year",
    past_due: "Past Due",
  },
  no_work_orders_available: "Looks like you don't have Work Orders yet.",
  add_work_orders: "Start adding work orders",
  press_to_add_work_orders:
    "Press the " + " button to add your first Work Order.",
  month: "Month",
  week: "Week",
  day: "Day",
  today: "Today",
  search_again: "Search again",
  no_related_QR_msg: "No related WO with this QR Code",
  how_to_add_WO: "how to add work orders",
  are_you_sure: "Are you sure want to",
  put_in_progress: "put in progress",
  put_on: "put on hold",
  this_WO: "this work order?",

  cancel_WO: "Are you sure you want to cancel this work order?",
  hold_WO: "Are you sure you want to hold this work order?",
  progress_WO: "Are you sure you want to set this work order as In-Progress?",
  reOpen_WO: "Are you sure you want to reopen this work order?",
  success_reOpen: "Work Order reopen successfully",

  success_cancel: "Work Order cancel successfully",
  success_hold: "Work Order set on hold successfully",
  success_progress: "Work Order set in progress successfully",
  success_approve: "Work Order completed successfully",
  success_decline: "Work Order closed successfully",
  success_open: "Work Order open successfully",

  set_in: "set in progress",
  set_on: "set on hold",
  successfully: "successfully",
  submit_error: "Please add reason to submit.",
  add_reason: "Add reason",
  required: "Add resaon (required)",
  complete_procedure: "% of procedure completion",
  assign: "Assign",
  assign_people: "Assigning people or teams",
  WO_assign_success: "WO assigned successfully",
  WO_edit: "This is one work order in the scheduled series.",
  what_edit: "What do you want to edit?",
  what_delete: "What do you want to delete?",
  this_wo: "Only this work order",
  this_series: "The entire schedule",
  complete: "Complete",
  approve_WO: "Are you sure that you have completed this work order?",
  decline_WO: "Are you sure you want to close this work order?",
  open_WO: "Are you sure you want to open this work order?",
  part_error:
    "Insufficient quantity available for the part added in this scheduled WO",
  set_error:
    "Insufficient quantity available for the sets added in this scheduled WO",
};
