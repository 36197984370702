import React, { useState, useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import {
  Segment,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  TextArea,
} from "semantic-ui-react";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import moment from "moment";
import { toast } from "react-toastify";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import useCancelModal from "../../../hooks/Messages/useCancelModal";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  getUsersByRole,
  getAllParentLocations,
  getLocationByID,
  getAllTeams,
  getAllCustomers,
  getAllVendros,
  updateMainLocation,
  getAllLocations,
  getAllAssets,
  getAllPeopleByLocation,
  getAllTeamsByLocation,
  getActiveUsersByRole,
  urlToFile,
  updateWorkRequest,
  uploadFile,
  getAllAssetsOption,
} from "../../config/functions";
import { useDropzone } from "react-dropzone";
import {
  AddIconWithCircle,
  AddImage,
  AddedFileIcon,
  CloseIcon,
  CsvImg,
  DeleteIcon,
  DocImg,
  DocxImg,
  FilesIcon,
  FlagwithFilled,
  ImgPhotos,
  Mp4File,
  PdfBlueIcon,
  PngPhotos,
  TextSnippet,
  TxtFile,
  XlsxFile,
} from "../../../Assets/Icons/svg";
import { selectTranslations } from "../../config/i18n/slice";
import ToolTip from "react-power-tooltip";
import Asterisk from "../../components/common/Asterisk";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { first } from "lodash";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { Resizable } from "re-resizable";

// const FileUpload = ({
//   onFileChange,
//   onCertificateFileChange,
//   name = "",
//   attachments = {},
//   setAttachments = () => {},
// }) => {
//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: (acceptedFiles) => {
//       if (name === "warrantyFile") {
//         onCertificateFileChange(acceptedFiles, name);
//       } else {
//         onFileChange(acceptedFiles, name);
//       }
//     },
//     accept:
//       name === "image"
//         ? { "image/jpeg": [".jpeg", ".png", ".jpg"] }
//         : undefined,
//   });
//   const t = useSelector(selectTranslations);
//   const selectedLanguage = useSelector((state) => {
//     if (state?.i18n?.lang) {
//       return state.i18n.lang;
//     } else {
//       return "en";
//     }
//   });
//   const renderFile = (file, index) => (
//     <div
//       className={`image-dropzone file d-flex justify-content-between text-center ${
//         index == 0 ? "" : "mt-5"
//       }`}
//     >
//       <div>
//         <label style={{ fontSize: "16px" }} className="mb-0">
//           <span
//             className={`${
//               selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
//             }`}
//           >
//             <TextSnippet />
//           </span>

//           {file?.name}
//         </label>
//       </div>

//       <div
//         className="mr-5 ml-5 mt-1 c-pointer"
//         onClick={(e) => {
//           e.stopPropagation();
//           const newCovidCopy = attachments.warrantyFile.filter(
//             (covidFile, i) => i !== index
//           );
//           setAttachments({
//             ...attachments,
//             warrantyFile: newCovidCopy,
//           });
//         }}
//       >
//         <CloseIcon />
//       </div>
//     </div>
//     // <div>
//     //   <label>
//     //     {file?.name}
//     //     <span
//     //       className="ml-3 mr-3 c-pointer"
//     //       onClick={(e) => {
//     //         e.stopPropagation();
//     //         const newCovidCopy = attachments.warrantyFile.filter(
//     //           (covidFile, i) => i !== index
//     //         );
//     //         setAttachments({
//     //           ...attachments,
//     //           warrantyFile: newCovidCopy,
//     //         });
//     //       }}
//     //     >
//     //       <DeleteIcon />
//     //     </span>
//     //   </label>
//     // </div>
//   );
//   // console.log(attachments);
//   //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
//   return (
//     <div {...getRootProps()}>
//       <input {...getInputProps()} />
//       {name === "image" ? (
//         attachments?.image?.name ? (
//           <div className="image-dropzone file d-flex justify-content-between text-center">
//             <div>
//               <label style={{ fontSize: "16px" }} className="mb-0">
//                 <span
//                   className={`${
//                     selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
//                   }`}
//                 >
//                   <AddedFileIcon />
//                 </span>

//                 {attachments?.image?.name}
//               </label>
//             </div>

//             <div
//               className="mr-5 ml-5 mt-1 c-pointer"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setAttachments({
//                   ...attachments,
//                   image: null,
//                 });
//               }}
//             >
//               <CloseIcon />
//             </div>
//           </div>
//         ) : (
//           <div className="image-dropzone text-center">
//             <div>
//               <div className="mb-3">
//                 <AddImage />
//               </div>
//               <p className="p1">
//                 <span className="mr-2 ml-2"></span>
//                 <span className="mt-1">{t.workRequests.form.drag}</span>
//                 <span className="mt-1" style={{ color: "#0C66E4" }}>
//                   {" "}
//                   {t.workRequests.form.choose}
//                 </span>{" "}
//                 <span className="mt-1">{t.workRequests.form.upload}</span>
//                 <p className="text-center">PNG or JPEG</p>
//               </p>
//             </div>
//           </div>
//         )
//       ) : attachments?.warrantyFile?.length > 0 ? (
//         <>
//           {attachments.warrantyFile.map((file, index) =>
//             renderFile(file, index)
//           )}

//           <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between mt-5">
//             <div className="d-flex">
//               <span className="mt-1">
//                 <TextSnippet />
//               </span>
//               <div className="pl-8 pr-8">
//                 <p className="p1 m-0">{t.workOrders.form.file}</p>
//                 <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
//               </div>
//             </div>
//             <div>
//               <AddIconWithCircle />
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
//           <div className="d-flex">
//             <span className="mt-1">
//               <TextSnippet />
//             </span>
//             <div className="pl-8 pr-8">
//               <p className="p1 m-0">{t.workOrders.form.file}</p>
//               <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
//             </div>
//           </div>
//           <div>
//             <AddIconWithCircle />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const FileUpload = ({
  onFileChange,
  name = "",
  onCertificateFileChange,
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const fileTypesCompare = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    txt: ["text/plain"],
    csv: ["text/csv"],
    docx: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xlsx: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    documents: ["application/msword"],
    pdf: ["application/pdf"],
    video: [
      "video/mp4",
      "video/quicktime",
      "application/octet-stream",
      "video/webm",
      "video/hevc",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept:
    //   name === "image"
    //     ? Object.fromEntries(
    //         fileTypes.images
    //           .concat(fileTypes.video)
    //           .map((type) => [type, []])
    //       )
    //     : Object.fromEntries(
    //         [
    //           ...fileTypes.images,
    //           ...fileTypes.documents,
    //           ...fileTypes.video,
    //         ].map((type) => [type, []])
    //       ),
    // accept:
    //   name === "image"
    //     ? {
    //         "image/jpeg": [],
    //         "image/jpg": [],
    //         "image/png": [],
    //         "audio/mpeg": [],
    //         "video/mp4": [],
    //       }
    //     : undefined, // No restrictions for other types
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images, ...fileTypes.video]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }

      let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;

      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);

        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      name === "image"
        ? onFileChange(validFiles, name)
        : onCertificateFileChange(validFiles, name);
    },
  });

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label
          style={{ fontSize: "14px" }}
          className="d-flex align-items-center m-1 mb-0"
        >
          <div
            className={`${
              selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
            }`}
          >
            {fileTypesCompare?.images?.includes(file?.type) ? (
              <PngPhotos />
            ) : fileTypesCompare?.txt?.includes(file?.type) ? (
              <TxtFile />
            ) : fileTypesCompare?.pdf?.includes(file?.type) ? (
              <PdfBlueIcon />
            ) : fileTypesCompare?.csv?.includes(file?.type) ? (
              <CsvImg />
            ) : fileTypesCompare?.documents?.includes(file?.type) ? (
              <DocImg />
            ) : fileTypesCompare?.video?.includes(file?.type) ? (
              <Mp4File />
            ) : fileTypesCompare?.docx?.includes(file?.type) ? (
              <DocxImg />
            ) : fileTypesCompare?.xlsx?.includes(file?.type) ? (
              <XlsxFile />
            ) : null}
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon width={"18px"} height={"18px"} />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div
            className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start
          `}
          >
            <div>
              <label
                style={{ fontSize: "14px" }}
                className="d-flex align-items-center m-1"
              >
                <div
                  className={`${
                    selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                  }`}
                >
                  {fileTypes?.images?.includes(attachments?.image.type) ? (
                    <PngPhotos />
                  ) : (
                    <Mp4File />
                  )}
                </div>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon width={"18px"} height={"18px"} />
            </div>
          </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.workOrders.drag_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_video}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone mt-5 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <FilesIcon />
              </span>
              <div className="pl-4 pr-4 d-flex align-items-center">
                <p className="p1 m-0">{t.common.files}</p>
                <span style={{ color: "#788CA5" }} className="ml-3">
                  {t.common.files_attachments}
                </span>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <FilesIcon />
            </span>
            <div className="pl-4 pr-4 d-flex align-items-center">
              <p className="p1 m-0">{t.common.files}</p>
              <span style={{ color: "#788CA5" }} className="ml-3">
                {t.common.files_attachments}
              </span>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
function Edit({
  targetedData,
  THELOCATION,
  toggled,
  untoggle,
  setSelectedDates,
  setSearchTitle,
  setFilterOptions,
  currentUserLocations,
}) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  // const currentUserLocations = useSelector((state) => {
  //   if (state?.auth?.user) {
  //     return state?.auth?.user?.locations;
  //   } else {
  //     return null;
  //   }
  // });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const t = useSelector(selectTranslations);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });
  const [cancelModal, toggleCancelModal] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [mapModal, toggleMapModal] = useState(false);
  const [locationAddedModal, toggleLocationAddedModal] = useState(false);
  const [fetchLocation, setFetchLocation] = useState(false);

  const [fetchingAssets, setFetchingAssets] = useState(true);
  const [fetchingLocations, setFetchingLocations] = useState(true);
  const [fetchingPeople, setFetchingPeople] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [firstFetch, setFirstFetch] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const [options, setOptions] = useState({
    locations: [],
    priority: [
      {
        key: 4,
        value: "none",
        color: "#788CA5",
        text: t.workOrders.form.priority_options[0],
      },
      {
        key: 0,
        value: "low",
        color: "#4FAB68",
        text: t.workOrders.form.priority_options[1],
      },

      {
        key: 1,
        value: "medium",
        color: "#E2B203",
        
        text: t.workOrders.form.priority_options[2],
      },
      {
        key: 2,
        value: "high",
        color: "#D14040",
        text: t.workOrders.form.priority_options[3],
      },
    ],

    people: [],
    teams: [],
    assets: [],
  });

  const fetchOptions = async () => {
    const [fetchDataAsset, people, teams] = await Promise.all([
      getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        9999,
        targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : !(role === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : []
          : []
        // !(role === 'super_admin' || role === 'admin') ? targetedData?.attributes?.location?.data?.id : []
      ),

      getAllPeopleByLocation(
        targetedData?.attributes?.location?.data?.id
          ? targetedData?.attributes?.location?.data?.id
          : !(role === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? targetedData?.attributes?.location?.data?.id
            : currentUserId
          : [],
        organisationId
      ),

      getAllTeamsByLocation(
        targetedData?.attributes?.location?.data?.id
          ? targetedData?.attributes?.location?.data?.id
          : !(role === "super_admin")
          ? targetedData?.attributes?.location?.data?.id
            ? targetedData?.attributes?.location?.data?.id
            : currentUserId
          : [],
        organisationId
      ),
    ]);

    let people_options = [];
    if (people?.length > 0) {
      people_options = people.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingPeople(false);
    }

    let teams_options = [];
    if (teams?.length > 0) {
      teams_options = teams.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingTeams(false);
    }

    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );

    let location_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (currentUserLocations?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (currentUserLocations?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => currentUserLocations?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (currentUserLocations?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        location_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            location_options.push(subOption);
          }
        });
      });

      // Update the options state
    }
    // if (currentUserLocations) {
    //   location_options = currentUserLocations.map((el) => {
    //     return {
    //       key: el.id,
    //       value: el.id,
    //       text: el.name,
    //     };
    //   });
    //   setFetchingLocations(false);
    // }

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];
    if (fetchDataAsset?.response?.length > 0) {
      fetchDataAsset.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(role === "super_admin")
            ? targetedData?.attributes?.location?.data?.id
              ? targetedData?.attributes?.location?.data?.id ==
                el?.attributes?.location?.data?.id
              : currentUserId?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(role === "super_admin")
                  ? targetedData?.attributes?.location?.data?.id
                    ? targetedData?.attributes?.location?.data?.id ==
                      childAsset?.attributes?.location?.data?.id
                    : currentUserId?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(role === "super_admin")
                      ? targetedData?.attributes?.location?.data?.id
                        ? targetedData?.attributes?.location?.data?.id ==
                          grandChildEl?.attributes?.location?.data?.id
                        : currentUserId?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
    }

    setOptions({
      ...options,
      assets: fetchDataAsset?.response?.length > 0 ? hierarchicalOptions : [],
      locations: fetchData?.response?.length > 0 ? location_options : [],
      people: people?.length > 0 ? people_options : [],
      teams: teams?.length > 0 ? teams_options : [],
    });
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();

      if (targetedData) {
        setData({
          ...data,
          title: targetedData?.attributes?.title || "",
          description: targetedData?.attributes?.description || "",
          priority: targetedData?.attributes?.priority
            ? targetedData?.attributes?.priority
            : "low",

          locations: targetedData.attributes?.location?.data
            ? targetedData.attributes.location.data.id
            : "",
          assets:
            targetedData.attributes?.assets?.data?.length > 0
              ? targetedData.attributes.assets.data.map((el_team) => el_team.id)
              : [],
          people: targetedData.attributes?.people?.data
            ? targetedData.attributes.people.data.map((el_team) => el_team.id)
            : [],
          teams:
            targetedData.attributes?.teams?.data?.length > 0
              ? targetedData.attributes.teams.data.map((el_team) => el_team.id)
              : [],
        });

        setAttachments({
          ...attachments,
          image: targetedData?.attributes.logo?.data
            ? {
                id: targetedData?.attributes.logo?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.logo?.data?.attributes.url,
                  targetedData?.attributes.logo?.data?.attributes.name
                ),
                name: targetedData?.attributes.logo?.data?.attributes.name,
                type: targetedData?.attributes.logo?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          warrantyFile:
            targetedData?.attributes?.attachments &&
            targetedData?.attributes?.attachments?.data?.length
              ? targetedData?.attributes.attachments?.data?.map((one) => {
                  return {
                    id: one?.id,
                    file: urlToFile(
                      one?.attributes?.url,
                      one?.attributes?.name
                    ),
                    name: one?.attributes?.name,
                    type: one?.attributes?.mime,
                    cancelPopup: false,
                    isChanged: false,
                  };
                })
              : [],
        });
      }
      // }
    }
  }, [targetedData, toggled]);
  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    // const fetchData = await getAllLocations(organisationId);

    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (currentUserLocations?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (currentUserLocations?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => currentUserLocations?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (currentUserLocations?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  const fetchAssets = async () => {
    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.locations
        ? [data?.locations]
        : !(role === "super_admin")
        ? data?.locations
          ? [data?.locations]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(role === "super_admin")
            ? data?.locations
              ? data?.locations == el?.attributes?.location?.data?.id
              : currentUserId?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(role === "super_admin")
                  ? data?.locations
                    ? data?.locations ==
                      childAsset?.attributes?.location?.data?.id
                    : currentUserId?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(role === "super_admin")
                      ? data?.locations
                        ? data?.locations ==
                          grandChildEl?.attributes?.location?.data?.id
                        : currentUserId?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });

      setOptions((prev) => {
        return {
          ...prev,
          assets: hierarchicalOptions,
        };
      });

      return hierarchicalOptions;
    }
  };

  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [data, setData] = useState({
    title: "",
    description: "",
    requestType: "",
    requestedByOptions: [],
    requestedBy: "",
    dueDate: moment().format("YYYY-MM-DD"),
    dueTime: "09:00",
    priority: "none",
    locationOptions: [],
    location: "",
    locations: "",
    people: [],
    teams: [],
    assets: [],
    attachmentsAdded: false,
  });

  const [responseData, setResponseData] = useState({});

  const [locationData, setLocationsData] = useState({
    name: "",
    address: "",
    isParent: false,
    parentLocation_options: [],
    parentLocation: "",
    fetchingParentLocations: false,
    customer_options: [],
    customer: "",
    vendors_options: [],
    vendors: [],
    teams_options: [],
    teams: [],
    fetchingTeams: false,
    workers_options: [],
    workers: [],
    fetchingWorkers: false,
    mainLocation: {},
    geofencingCoords: [],
  });
  const [customFields, setCustomData] = useState([]);

  const resetForm = () => {
    setData({
      title: "",
      description: "",
      requestType: "",
      requestedByOptions: [],
      requestedBy: "",
      dueDate: moment().format("YYYY-MM-DD"),
      dueTime: "09:00",
      priority: "low",
      locationOptions: [],
      location: "",
      locations: "",
      people: [],
      teams: [],
      assets: [],
      attachmentsAdded: false,
    });

    setValidation({
      title: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
    });
    setAttachments({
      image: null,
      warrantyFile: [],
    });
    setInitChange(false);
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setOptions({
      locations: [],
      priority: [
        {
          key: 4,
          value: "none",
          color: "#788CA5",
          text: t.workOrders.form.priority_options[0],
        },
        {
          key: 0,
          value: "low",
          color: "#4FAB68",
          text: t.workOrders.form.priority_options[1],
        },
  
        {
          key: 1,
          value: "medium",
          color: "#E2B203",
          
          text: t.workOrders.form.priority_options[2],
        },
        {
          key: 2,
          value: "high",
          color: "#D14040",
          text: t.workOrders.form.priority_options[3],
        },
      ],

      people: [],
      teams: [],
      assets: [],
    });
    setFirstFetch(false);
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    // console.log(event.target.value);
    let currentFieldValue = event.target.value;
    const dropdown_fields = [
      "requestType",
      "requestedBy",
      "locations",
      "people",
      "teams",
      "assets",
    ];
    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      if (name == "locations") {
        setData({
          ...data,
          [name]: value.value,
          people: [],
          teams: [],
          assets: [],
        });

        setOptions({
          ...options,
          people: [],
          teams: [],
          assets: [],
        });
        // setFirstFetch((pre) => !pre);
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const fetchPeopleOptions = async (customerID, locations) => {
    const fetchData = await getAllPeopleByLocation(
      data?.locations
        ? data?.locations
        : !(role === "super_admin")
        ? data?.locations
          ? data?.locations
          : currentUserId
        : [],
      organisationId
    );
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const fetchTeamsOptions = async (customerID, locations) => {
    const fetchData = await getAllTeamsByLocation(
      data?.locations
        ? data?.locations
        : !(role === "super_admin")
        ? data?.locations
          ? data?.locations
          : currentUserId
        : [],
      organisationId
    );
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeople = async () => {
    if (!data.locations) {
      const fetchData = await getActiveUsersByRole(
        ["limited_admin"],
        organisationId
      );
      if (fetchData.data) {
        let the_options = fetchData.data.map((el) => {
          const the_image = el.profileImage?.url
            ? el.profileImage?.url
            : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
          let teams_string_array = el?.teams?.map((one) => one.name);
          return {
            key: el.id,
            value: el.id,
            text:
              el.firstName +
              " " +
              el.lastName +
              " (" +
              t.people.roles[el.role.name] +
              ")",
            // description: `${teams_string_array.join(' , ')}`,
            image: { avatar: true, src: the_image, size: "tiny" },
          };
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          people: the_options,
        }));

        return the_options;
      }
    }
  };

  /*
   * The function fetches team options data from an API and formats it for display.
   */
  const fetchTeams = async () => {
    if (!data.locations) {
      const fetchData = await getAllTeams(organisationId);

      if (fetchData.response) {
        let the_options = fetchData.response.map((el) => {
          const the_image = LetteredAvatarImageUrl(el.attributes.name);

          return {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { avatar: true, src: the_image, size: "tiny" },
          };
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          teams: the_options,
        }));

        return the_options;
      }
    }
  };

  // useEffect(() => {
  //   if (data?.locations) {
  //     setData({
  //       ...data,
  //       people: [],
  //       teams: [],
  //     });
  //     setOptions({
  //       ...options,
  //       people: [],
  //       teams: [],
  //     });
  //     setFetchLocation(true);
  //     fetchPeopleOptions(data.locations ? data.locations : []);
  //     fetchTeamsOptions(data.locations ? data.locations : []);
  //     setFetchLocation(false);
  //   }
  // }, [firstFetch]);

  const updateWorkRequestHandler = async () => {
    // validationHandler();

    await validationHandler();

    const validationToArray = Object.entries(validation);
    const errorsInData = validationToArray.some(
      (el) => el[1].error && el[1].skipped
    );
    if (!errorsInData) {
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      let theAttachments =
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : [];
      let unchangedAttachments = attachments.warrantyFile.filter(
        (el) => !el.isChanged
      );
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      updateWorkRequest2Handler(
        assetImageReponse?.status == 200
          ? assetImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null,
        toBeSendedCertificateFiles
      );
    } else {
      scrollToError();
    }
  };

  const updateWorkRequest2Handler = async (image, warranty) => {
    const response = await updateWorkRequest(
      Number(targetedData.id),
      data.title ? String(data.title) : null,
      data.description ? String(data.description) : null,
      data.priority ? String(data.priority) : "low",
      data.locations ? Number(data.locations) : null,
      data.assets ? data.assets : null,
      data.people ? data.people : null,
      data.teams ? data.teams : null,
      image,
      warranty,
      organisationId,
      currentUserId
    );

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });
      setFilterOptions({
        requestedBy: [],
        locations: [],
        assets: [],
        priority: [],
        status: [],

        priority: [],
        status: [],
      });

      setSearchTitle("");
      setSelectedDates([]);

      toast.success(t.workRequests.form.update_success);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        resetForm();
      }
      setButtonStatus(0);
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  /*
   * The `onFileChange` function sets a new file attachment with random ID and details based on the input files and name.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);
    // console.log('show', e.target.files[0]);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  // useEffect(() => {
  //   // fetchUsersData();
  //   // fetchParentLocations();
  //   // fetchParentLocations();
  //   // fetchTeamsData();
  //   // fetchCustomerData();

  //   // console.log("fetchData from useEffect", THELOCATION);
  //   let current_parentLocation = THELOCATION?.parentLocation
  //     ? THELOCATION?.parentLocation.id
  //     : '';

  //   let current_customer = THELOCATION?.customer
  //     ? THELOCATION?.customer.id
  //     : '';

  //   let current_vendors =
  //     THELOCATION?.vendors.length > 0
  //       ? THELOCATION?.vendors.map((el) => el.id)
  //       : [];

  //   let current_teams =
  //     THELOCATION?.teams.length > 0
  //       ? THELOCATION?.teams.map((el) => el.id)
  //       : [];

  //   let current_workers =
  //     THELOCATION?.workers.length > 0
  //       ? THELOCATION?.workers.map((el) => el.id)
  //       : [];
  //   setLocationsData({
  //     ...locationData,
  //     name: THELOCATION?.name,
  //     address: THELOCATION?.address,
  //     isParent: THELOCATION?.isParent,
  //     parentLocation: current_parentLocation,
  //     customer: current_customer,
  //     vendors: current_vendors,
  //     teams: current_teams,
  //     workers: current_workers,
  //     mainLocation: THELOCATION?.mainLocation,
  //     geofencingCoords: THELOCATION?.geofencingCoords,
  //   });

  //   setCustomData(THELOCATION?.customFields ? THELOCATION?.customFields : []);
  // }, [THELOCATION]);

  const createNewLocationHandler = async () => {
    // setButtonStatus(1);
    // const isParentHandler = locationData.parentLocation ? false : true;
    // const response = await createNewLocation(
    //   locationData.name,
    //   locationData.address,
    //   isParentHandler,
    //   locationData.parentLocation,
    //   locationData.customer,
    //   locationData.vendors,
    //   locationData.teams,
    //   locationData.workers,
    //   locationData.mainLocation,
    //   locationData.geofencingCoords,
    //   customFields
    // );
    // // console.log(response);
    // if (response.status == 200) {
    //   setButtonStatus(0);
    //   untoggle();
    //   resetForm();
    //   setResponseData({
    //     id: response.data.id,
    //     name: response.data.name,
    //     isParent: response.data.isParent,
    //   });
    //   toggleSuccessModal(true);
    // }
  };

  const generatedCustomDataFields = () => {
    return customFields.map((field, index) => {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Label</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.label}
                onChange={handleCustomDataChange(index, "label")}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Value</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.value}
                onChange={handleCustomDataChange(index, "value")}
              />
            </Form.Field>
          </Grid.Column>

          <div
            className="buttun-type-link remove"
            basic
            onClick={removeThisData(field.id)}
          >
            <Icon name="trash alternate outline" />
            Remove
          </div>
        </Grid>
      );
    });
  };

  const addCustomData = () => {
    // console.log("clicked");
    setCustomData([
      ...customFields,
      {
        id: customFields.length,
        label: "",
        value: "",
      },
    ]);
  };

  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...customFields];
    newfields.splice(index, 1);

    setCustomData(newfields);
  };

  const handleCustomDataChange = (id, type) => (event) => {
    // 1. Make a shallow copy of the items
    let newfields = [...customFields];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...customFields[id] };
    // 3. Replace the property you're intested in

    if (type == "label") item.label = event.target.value;
    if (type == "value") item.value = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    newfields[id] = item;
    // 5. Set the state to our new copy
    setCustomData(newfields);
  };
  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    toggleCancelModal(false);
  };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.workRequests.edit_WR} #{targetedData?.attributes?.countId}
              </div>
              {/* <div>
              <Icon
                name="close"
                size="large"
                title="Close"
                onClick={() => toggleCancelModal(true)}
              />
            </div> */}

              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                  // onClick={() => toggleCancelModal(true)}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    show={showCancleTooltip}
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            className="body"
          >
            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.title}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.workOrders.form.title_name}
                value={data.title}
                onChange={onValuesChange("title")}
                error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <FileUpload
              onFileChange={onFileChange}
              name="image"
              attachments={attachments}
              setAttachments={setAttachments}
            />

            <Form style={{ marginBottom: "20px" }}>
              <Form.Field>
                <label className="label-item mt-5">
                  {t.workOrders.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={4}
                  fluid
                  placeholder={t.workOrders.form.description_text}
                />
              </Form.Field>
            </Form>

            {/* <Form.Field>
              <label className="label-item">
                {" "}
                <label className="label-item">
                  {t.workOrders.table.priority}
                </label>
              </label>
              <Dropdown
                placeholder={t.workOrders.table.add_priority}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                options={options.priority}
                value={data.priority}
                onChange={(e, value) => {
                  let findPriority = value.options.find(
                    (one) => one.value == value.value
                  );
                  if (findPriority) {
                    setData({
                      ...data,
                      priority: value.value,
                    });
                  }
                }}
              />
            </Form.Field> */}

            <div className="priority-container">
              <label className="label-item">
                {t.workOrders.table.priority}
                <Asterisk color="#172B4D" />
              </label>

              <div className="priority-options">
                {options?.priority?.map((priority) => (
                  <div
                    key={priority?.value}
                    className={`priority-btn wr ${
                      data?.priority === priority?.value
                        ? `selected-${priority?.value}`
                        : ""
                    }`}
                    onClick={() =>
                      setData({
                        ...data,
                        priority: priority.value,
                      })
                    }
                  >
                    <FlagwithFilled color={priority?.color} />
                    <span>{priority?.text}</span>
                  </div>
                ))}
              </div>
            </div>

            <Form.Field>
              <label className="label-item mt-5">
                {t.workRequests.form.location}
              </label>
              <Dropdown
                className="loc-dropdown"
                placeholder={t.workRequests.form.add_location}
                fluid
                noResultsMessage={t.common.no_results_found}
                selection
                search
                clearable
                options={options.locations}
                value={data.locations}
                onChange={onValuesChange("locations")}
                onOpen={fetchLocations}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.assets}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_assets}
                fluid
                // disabled={
                //   !data.locations &&
                //   !(role === "super_admin" || role === "admin")
                // }
                noResultsMessage={t.common.no_results_found}
                selection
                search
                className="people-dropdown"
                multiple
                options={options.assets}
                value={data.assets}
                onChange={(e, value) => {
                  // Find the currently selected options
                  const selectedOptions = options.assets.filter((option) =>
                    value?.value.includes(option.value)
                  );

                  let newSelectedValues = [...value?.value];

                  selectedOptions.forEach((option) => {
                    // When selecting a parent asset
                    if (
                      !option?.disabled &&
                      option.className === "main-location-dropdown-option"
                    ) {
                      // Find child assets of this parent
                      const childOptions = options.assets.filter(
                        (subOption) =>
                          subOption.parentId === option.key &&
                          !subOption.disabled
                      );

                      // Add child assets to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...childOptions.map((child) => child.value),
                      ];

                      // For each child, find its grandchildren (if the child is selected)
                      childOptions.forEach((child) => {
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === child.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      });
                    }

                    // When selecting a child asset
                    if (
                      !option?.disabled &&
                      option.className === "sub-location-dropdown-option"
                    ) {
                      // Find grandchild assets of this child
                      const grandChildren = options.assets.filter(
                        (grandChild) =>
                          grandChild.childParentId === option.key &&
                          !grandChild.disabled
                      );

                      // Add grandchildren to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...grandChildren.map((grandChild) => grandChild.value),
                      ];
                    }
                  });

                  // Update state with new selected values
                  setData({
                    ...data,
                    assets: [...new Set(newSelectedValues)], // Ensure unique values only
                  });
                }}
                onOpen={fetchAssets}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.people}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_people}
                // disabled={fetchLocation}
                fluid
                // disabled={
                //   !data.locations &&
                //   !(role === "super_admin" || role === "admin")
                // }
                noResultsMessage={t.common.no_results_found}
                selection
                search
                multiple
                options={options.people}
                value={data.people}
                onChange={onValuesChange("people")}
                onOpen={fetchPeopleOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.teams}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_teams}
                fluid
                // disabled={
                //   !data.locations &&
                //   !(role === "super_admin" || role === "admin")
                // }
                noResultsMessage={t.common.no_results_found}
                selection
                search
                multiple
                options={options.teams}
                value={data.teams}
                onChange={onValuesChange("teams")}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>

            <Form.Field>
              <FileUpload
                onCertificateFileChange={onCertificateFileChange}
                name="warrantyFile"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>
            {/* <Form.Field>
            <label className="label-item">Location Name</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Enter Location Name"
              value={locationData.name}
              onChange={onValuesChange('name')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Address</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Address"
              value={locationData.address}
              onChange={onValuesChange('address')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Parent Location</label>
            <Dropdown
              placeholder="Choose from existing Location"
              fluid
              search
              selection
              clearable
              loading={locationData.fetchingParentLocations}
              options={locationData.parentLocation_options}
              value={locationData.parentLocation}
              onChange={onValuesChange('parentLocation')}
              onOpen={() => fetchParentLocations()}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Workers</label>
            <Dropdown
              placeholder="Select Users"
              fluid
              multiple
              search
              selection
              options={locationData.workers_options}
              value={locationData.workers}
              onChange={onValuesChange('workers')}
              onOpen={() => fetchUsersData()}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Teams</label>
            <Dropdown
              placeholder="Select Teams"
              fluid
              multiple
              search
              selection
              options={locationData.teams_options}
              value={locationData.teams}
              onChange={onValuesChange('teams')}
              onOpen={() => fetchTeamsData()}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Customer</label>
            <Dropdown
              placeholder="Select Customer"
              fluid
              search
              clearable
              selection
              options={locationData.customer_options}
              value={locationData.customer}
              onChange={onValuesChange('customer')}
              onOpen={() => fetchCustomerData()}
            />
          </Form.Field> */}

            {/* <Form.Field>
            <label className="label-item">Vendors</label>
            <Dropdown
              placeholder="Select Vendors"
              fluid
              search
              multiple
              selection
              options={locationData.vendors_options}
              value={locationData.vendors}
              onChange={onValuesChange('vendors')}
              onOpen={() => fetchVendorsData()}
            />
          </Form.Field>

          <Divider />
          {generatedCustomDataFields()}
          <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            Custom Data
          </div>

          <Divider /> */}

            <div className="form-button-container mt-13">
              <button
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={updateWorkRequestHandler}
                className="form-action-button blue"
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>
      {/* success modal */}
      {/* <Modal
        size="tiny"
        open={successModal}
        onClose={() => toggleSuccessModal(false)}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <Header as="h2">
              Location <b>{responseData.name}</b> added successfully!
            </Header>
            <Header as="h3">
              Would you like to add <b>{responseData.name}</b> on map?
            </Header>
          </div>
        </Modal.Content>
        {responseData.isParent ? (
          <Modal.Actions>
            <Button color="black" onClick={() => toggleSuccessModal(false)}>
              Later
            </Button>
            <Button
              content="Add on map"
              labelPosition="right"
              icon="add"
              positive
              onClick={() => {
                toggleSuccessModal(false);
                toggleMapModal(true);
              }}
            />
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button color="black" onClick={() => toggleSuccessModal(false)}>
              Ok
            </Button>
          </Modal.Actions>
        )}
      </Modal> */}

      {/* success add THELOCATION? on map modal */}
      {/* <Modal
        size="tiny"
        open={locationAddedModal}
        onClose={() => toggleLocationAddedModal(false)}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <Header as="h2">
              Location <b>{responseData.name}</b> added on map successfully!
            </Header>
            <Header as="h3">
              Would you like to add Geofencing Coordinates on map?
            </Header>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleLocationAddedModal(false)}>
            Later
          </Button>
          <Button
            content="Add on map"
            labelPosition="right"
            icon="add"
            positive
            onClick={() => {
              toggleLocationAddedModal(false);
              toggleGeofencingModal(true);
            }}
          />
        </Modal.Actions>
      </Modal> */}

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      {/* <CancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        deleteErrorMessage={true}
      >
        <span>{t.common.cancel_statement}</span>
      </CancelModal> */}
      {/* <Modal
        size="tiny"
        open={cancelModal}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
    </Modal> */}
    </>
  );
}

export default Edit;
